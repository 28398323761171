/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./Header";
import Footer from "./Footer";

import "./layout.css";

type LayoutProps = {
  readonly header?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, header = true }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      {header && <Header siteTitle={data.site.siteMetadata.title} />}
      <div className="px-3 lg:px-8">
        <div className="container mx-auto">{children}</div>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
