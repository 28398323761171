import React from "react";
import { Link } from "gatsby";

import ccLogo from "../images/cc-4.0-by-88x31.png";

const Footer: React.FC = () => (
  <footer className="flex flex-wrap flex-row items-center content-between justify-between mt-24 p-8">
    <div className="flex flex-row items-center">
      <a
        className="no-underline"
        rel="license"
        href="http://creativecommons.org/licenses/by/4.0/deed.en_US"
      >
        <img className="mr-1" src={ccLogo} alt="Creative Commons License" />
      </a>
      <span className="hidden lg:inline-block lg:pl-2">
        © {new Date().getFullYear()} Panic Bear, LLC. All content on this
        site is licensed under a{" "}
        <a
          className="no-underline text-blue-700 hover:text-blue-700"
          rel="license"
          href="http://creativecommons.org/licenses/by/4.0/deed.en_US"
        >
          Creative Commons Attribution 4.0 International License
        </a>
        .
      </span>
    </div>

    <Link
      className="no-underline text-blue-700 hover:text-blue-700"
      to="/legal/"
    >
      Legal &amp; Privacy
    </Link>
  </footer>
);

export default Footer;
