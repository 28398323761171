import React from "react";
import { Link } from "gatsby";

import { MobileMenu, MobileMenuToggle, TopLinks } from "./MobileMenu";

const { useState } = React;

const Header: React.FC<{ readonly siteTitle: string }> = ({ siteTitle }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  return (
    <header className="flex flex-wrap flex-row content-between justify-between mb-12 border-b">
      <div className="flex flex-row items-center justify-between py-3 px-4 w-full">
        <div className="flex flex-row items-center justify-between">
          <h1 className="font-bold leading-none inline-block mb-1 ml-2">
            <Link
              className="text-black hover:text-black no-underline text-3xl"
              to="/"
            >
              {siteTitle}
            </Link>
          </h1>
        </div>
        <TopLinks />
        <MobileMenuToggle
          onClick={(): void => setMobileMenuVisible(!mobileMenuVisible)}
        />
      </div>
      <MobileMenu className={mobileMenuVisible ? "" : "hidden"} />
    </header>
  );
};

export default Header;
