import React from "react";

const links = {
  email: "paul.dlug@gmail.com",
  twitter: "pdlug",
  linkedin: "pauldlug",
  github: "pdlug",
};

export const TopLinks: React.FC = () => (
  <nav className="hidden lg:block mb-2">
    <a
      className="text-blue-700 inline-block mr-3 no-underline"
      href={`mailto:${links.email}`}
      itemProp="email"
      rel="me"
      title="Email"
    >
      {links.email}
    </a>
    <a
      className="text-blue-700 inline-block mr-3 no-underline"
      href={`https://twitter.com/${links.twitter}`}
      rel="me"
      title="Twitter"
    >
      @{links.twitter}
    </a>
    <a
      className="text-blue-700 inline-block mr-3 no-underline"
      href={`https://www.linkedin.com/in/${links.linkedin}`}
      rel="me"
      title="LinkedIn"
    >
      linkedin
    </a>
    <a
      className="text-blue-700 inline-block no-underline"
      href={`https://github.com/${links.github}`}
      rel="me"
      title="GitHub"
    >
      github
    </a>
  </nav>
);

export const MobileMenu: React.FC<{ readonly className?: string }> = ({
  className,
}) => (
  <ul className={`list-reset w-full ${className}`}>
    <li className="py-4 px-4 border-gray-400 border-t">
      <a
        className="text-blue-700 no-underline"
        href={`mailto:${links.email}`}
        itemProp="email"
        rel="me"
        title="Email"
      >
        {links.email}
      </a>
    </li>
    <li className="py-4 px-4 border-gray-400">
      <a
        className="text-blue-700 no-underline"
        href={`https://twitter.com/${links.twitter}`}
        rel="me"
        title="Twitter"
      >
        @{links.twitter}
      </a>
    </li>
    <li className="py-4 px-4 border-gray-400">
      <a
        className="text-blue-700 no-underline"
        href={`https://www.linkedin.com/in/${links.linkedin}`}
        rel="me"
        title="LinkedIn"
      >
        linkedin
      </a>
    </li>
    <li className="py-4 px-4 border-gray-400">
      <a
        className="text-blue-700 no-underline"
        href={`https://github.com/${links.github}`}
        rel="me"
        title="GitHub"
      >
        github
      </a>
    </li>
  </ul>
);

export const MobileMenuToggle: React.FC<{ readonly onClick?: () => void }> = ({
  onClick,
}) => (
  <>
    <div className="lg:hidden">
      <button className="no-underline" title="Menu" onClick={onClick}>
        <svg
          aria-hidden="true"
          height="32"
          viewBox="0 0 32 32"
          width="32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M4 10h24c1.104 0 2-.896 2-2s-.896-2-2-2H4c-1.104 0-2 .896-2 2s.896 2 2 2zm24 4H4c-1.104 0-2 .896-2 2s.896 2 2 2h24c1.104 0 2-.896 2-2s-.896-2-2-2zm0 8H4c-1.104 0-2 .896-2 2s.896 2 2 2h24c1.104 0 2-.896 2-2s-.896-2-2-2z" />
        </svg>
      </button>
    </div>
  </>
);
